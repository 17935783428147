"use client";
import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Accordion,
  AccordionItem,
} from "@heroui/react";
import Link from "next/link";
import styles from "./index.module.scss";
export const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const services = [
    {
      url: "/servicios/Marketing_Digital",
      text: "Marketing Digital",
      image: "/images/services/marketing_digital-1-612x396.webp",
      classname: "md:col-span-4 col-span-12 h-[300px]",
    },
    {
      url: "/servicios/Desarrollo_Web",
      text: "Desarrollo Web",
      image: "/images/services/tecnologia_IT-1-612x396.webp",
      classname: "md:col-span-3 lg:col-span-3 col-span-12 h-[300px]",
    },
    {
      url: "/#servicios",
      text: "Servicios",
      image: "/images/services/diseno_digital_2-2-612x396.webp",
      classname: "md:col-span-7 col-span-12 h-[300px]",
      children: [
        {
          text: "Marketing Digital",
          url: "/servicios/marketing_digital",
          children: [
            {
              text: "SEO",
              url: "/servicios/Marketing_digital/posicionamiento_seo",
            },
            {
              text: "SEM",
              url: "/servicios/Marketing_digital/sem",
            },
            // {
            //   text: "CRO",
            //   url: "/servicios/Marketing_digital/cro",
            // },
            {
              text: "Community Manager",
              url: "/servicios/Marketing_digital/community_manager",
            },
            {
              text: "E-mail Marketing",
              url: "/servicios/Marketing_digital/email_marketing",
            },
            {
              text: "Social Media",
              url: "/servicios/Marketing_digital/social_media",
            },
            {
              text: "Estrategia digital",
              url: "/servicios/Marketing_digital/estrategia_digital",
            },
          ],
        },
        {
          text: "Diseño Web",
          url: "/servicios/diseno_web",
          children: [
            {
              text: "Diseño Web a medida",
              url: "/servicios/diseno_web/diseno_web_a_medida",
            },
            {
              text: "Diseño UX/UI",
              url: "/servicios/diseno_web/diseno_ux_ui",
            },
            // {
            //   text: "Diseño de tiendas online",
            //   url: "/servicios/diseno_web/diseno_ecommerce",
            // },
            {
              text: "Design Systems",
              url: "/servicios/diseno_web/design_systems",
            },
          ],
        },
        {
          text: "Desarrollo web",
          url: "/servicios/desarrollo_web",
          children: [
            {
              text: "Desarrollo web",
              url: "/servicios/Desarrollo_web",
            },
            {
              text: "Mantenimeinto web",
              url: "/servicios/desarrollo_web/mantenimiento_web",
            },
            {
              text: "Atomic Design",
              url: "/servicios/desarrollo_web/atomic_design",
            },
            // {
            //   text: "Design Systems",
            //   url: "/servicios/desarrollo_web/design_systems",
            // },
          ],
        },
        {
          text: "Ecommerce",
          url: "/servicios/ecommerce",
          children: [
            {
              text: "Diseño de tiendas Online",
              url: "/servicios/ecommerce/diseno_ecommerce",
            },
            {
              text: "Desarrollo de tiendas Online",
              url: "/servicios/ecommerce/desarrollo_ecommerce",
            },
          ],
        },
        {
          text: "Aplicaciones móviles",
          url: "/servicios/aplicaciones_moviles",
          image: "/images/services/diseno_digital_2-2-612x396.webp",
          children: [
            {
              text: "Diseño de aplicaciones móviles",
              url: "/servicios/aplicaciones_moviles/diseno_de_aplicaciones_moviles",
            },
            {
              text: "Desarrollo de aplicaciones móviles",
              url: "/servicios/aplicaciones_moviles/desarrollo_de_aplicaciones_moviles",
            },
            {
              text: "Posicionamiento ASO",
              url: "/servicios/aplicaciones_moviles/posicionamiento_aso",
            },
          ],
        },
        {
          text: "Branding",
          url: "/servicios/branding",
          children: [
            {
              text: "Identidad corporativa",
              url: "/servicios/branding/identidad_corporativa",
            },
            {
              text: "Diseño gráfico",
              url: "/servicios/branding/diseno_grafico",
            },
            {
              text: "Naming",
              url: "/servicios/branding/naming",
            },

          ],
        },
      ],
    },
    {
      url: "/kitDigital",
      text: "Kit Digital",
      image: "/images/services/diseno_digital_2-2-612x396.webp",
      classname: "md:col-span-7 col-span-12 h-[300px]",
    },
    {
      url: "/agencia",
      text: "Agencia",
      image: "/images/services/diseno_digital_2-2-612x396.webp",
      classname: "md:col-span-7 col-span-12 h-[300px]",
    },

    {
      url: "/blog",
      text: "Blog",
      image: "/images/services/branding-1-612x396.webp",
      classname: "md:col-span-3 lg:col-span-3 col-span-12 h-[300px]",
    },

    {
      url: "/contacto",
      text: "Contacto",
      image: "/images/services/estrategia_digital-2-612x396.webp",
      classname: "md:col-span-4 col-span-12 h-[300px]",
    },

  ];




  const icon = "I<.web";


  const [scroll, setScroll] = useState(false);
  const [paddingTop, setPaddingTop] = useState("12px"); // Ajusta el margen inicial
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isVisible, setIsVisible] = useState(true); // Estado para manejar la visibilidad del navbar
  const [iconSize, setIconSize] = useState("text-4xl"); // Estado para el tamaño del icono

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY === 0) {
        setIsVisible(true);
        setIconSize("text-4xl"); // Icono en 4xl si está en la parte superior
        setPaddingTop("12px");
      } else {
        setIconSize("text-3xl"); // Cambia a 3xl al hacer scroll
        setIsVisible(currentScrollY <= 450 || currentScrollY < lastScrollY); // Controla la visibilidad del navbar
      }

      setLastScrollY(currentScrollY);
      setScroll(currentScrollY >= 778);
      setPaddingTop(currentScrollY >= 50 ? "0px" : "12px");
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll); // Limpiar el event listener
    };
  }, [lastScrollY]);

  return (
        <Navbar
        className={`fixed top-0 left-0 w-full px-5 transition-all duration-300 
          ${scroll || isMenuOpen ? "bg-black text-white" : "bg-transparent text-white"} 
          ${styles["c-navbar"]}`}
        style={{
          paddingTop: paddingTop, 
          opacity: isVisible || window.scrollY === 0 ? 1 : 0,
          transition: 'opacity 0.5s ease, padding-top 0.3s ease',
          // backdropFilter: scroll || isMenuOpen ? "blur(1px)" : "none"
        }}
        isMenuOpen={isMenuOpen}
        onMenuOpenChange={setIsMenuOpen}
        aria-label="Barra de navegación principal"
      >
      <NavbarContent>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Cerrar menú" : "Abrir menú"}
          className="sm:hidden bg:black"
        />
        <NavbarBrand>
          <Link href="/" aria-label="Ir a la página de inicio">
            <span className={`font-bold transition-all duration-500 ${iconSize} text-white`}>
              {icon}
            </span>
          </Link>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        {services.slice(0, 2).map((service, index) => (
          <NavbarItem className="group" onClick={() => setIsMenuOpen(false)} key={index}>
            <Link
              color="foreground"
              className="block relative font-medium hover:text-current"
              href={service.url}
              aria-label={`Ir a ${service.text}`}
            >
              <span className="relative inline-block mt-1 mb-1">{service.text}</span>
              <span className="absolute h-0.5 -bottom-1 bg-current left-0 w-0 group-hover:w-full transition-all duration-300"></span>
            </Link>
          </NavbarItem>
        ))}
        <Dropdown
          className="bg-black"
          radius={"none"}
          onOpenChange={(state) => setIsDropdownOpen(state)}
        >
          <NavbarItem>
            <DropdownTrigger className="group">
              <Button
                disableRipple
                className={`${styles['button-dropdown']} block relative text-md font-medium hover:text-current ${scroll ? "text-white" : "text-white"}`}
                endContent={
                  <span
                    className={`ml-2 fa-solid fa-chevron-down ${isDropdownOpen ? "rotate-180" : ""} transition-transform duration-300`}
                  ></span>
                }
                radius={"none"}
                variant="light"
              >
                {services[2].text}
                <span className="absolute h-0.5 bg-current bottom-0 left-0 w-0 group-hover:w-full transition-all duration-300"></span>
              </Button>
            </DropdownTrigger>
          </NavbarItem>
          <DropdownMenu className={styles["dropdown-menu"]}>
            {services[2].children!.map((item, index) => (
              <DropdownItem
                className="max-w-[450px] my-5 ml-10 mt-10 hover:bg-transparent justify-start overflow-auto"
                key={item.text + index}
              >
                <Link href={item.url} className="text-xl font-semibold" aria-label={`Ir a ${item.text}`}>
                  <span className="text-xl font-semibold">{item.text}</span>
                </Link>
                {item.children?.length && item.children.map((child, index) => (
                  <ul key={child.text + index}>
                    <Link href={child.url} aria-label={`Ir a ${child.text}`}>
                      <li className="font-light text-lg block relative py-1">
                        <span className="hover:bg-gray-900 transition duration-300 px-3">{child.text}</span>
                      </li>
                    </Link>
                  </ul>
                ))}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        {services.slice(3, services.length - 1).map((service, index) => (
          <NavbarItem className="group" onClick={() => setIsMenuOpen(false)} key={index}>
            <Link
              color="foreground"
              className="block relative font-medium hover:text-current"
              href={service.url}
              aria-label={`Ir a ${service.text}`}
            >
              <span className="relative inline-block mt-1 mb-1">{service.text}</span>
              <span className="absolute h-0.5 -bottom-1 bg-current left-0 w-0 group-hover:w-full transition-all duration-300"></span>
            </Link>
          </NavbarItem>
        ))}
      </NavbarContent>
      <NavbarContent justify="end" style={{ width: '80px' }}>
        <NavbarItem>

        <Button
          as={Link}
          href="/contacto"
          aria-label="Ir a la página de contacto"
          color="default"
          variant="bordered"
          radius="none"
          style={{
            border: '1px solid white',
            color: 'white',
            backgroundColor: 'transparent',
            transition: 'all 0.3s ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = 'white';
            e.currentTarget.style.color = 'black';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = 'transparent';
            e.currentTarget.style.color = 'white';
          }}
        >
          Tengo un proyecto
        </Button>

        </NavbarItem>
      </NavbarContent>
      <NavbarMenu className="bg-black pb-1">
        <div className="mt-5">
          {services.map((service, index) => (
            <NavbarMenuItem className="mb-2" onClick={() => setIsMenuOpen(false)} key={index}>
              {!service.children?.length && (
                <Link className="w-full group" href={service.url} aria-label={`Ir a ${service.text}`}>
                  <span className="block relative font-bold hover:text-current w-max">
                    {service.text}
                    <span className="absolute bg-current bottom-0 left-0 w-0 group-hover:w-full transition-all duration-300"></span>
                  </span>
                </Link>
              )}
              {service.children?.length && (
                <Accordion showDivider={false} className={styles["accordion-menu"]}>
                  <AccordionItem
                    title={service.text}
                    className={`block relative font-bold hover:text-current ${styles["accordion-menu"]}`}
                  >
                    <Accordion
                      dividerProps={{ "aria-hidden": true }}
                      showDivider={false}
                      className="p-0 m-0 font-medium"
                    >
                      {service.children.map((item, index) => (
                        <AccordionItem
                          hideIndicator={!item.children}
                          className={styles["accordion-level-1"]}
                          title={item.text}
                          key={item.text + index}
                        >
                          {item.children?.length && item.children.map((child, index) => (
                            <Link
                              key={child.text + index}
                              className={styles["accordion-level-2"]}
                              href={child.url}
                              aria-label={`Ir a ${child.text}`}
                            >
                              <span className="block relative text-2xl font-light hover:text-current w-max">
                                {child.text}
                              </span>
                            </Link>
                          ))}
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </AccordionItem>
                </Accordion>
              )}
            </NavbarMenuItem>
          ))}
        </div>
      </NavbarMenu>
    </Navbar>
  );
};
